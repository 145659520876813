import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as _ from 'lodash';
import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import {superAdminNav, shippingLineNav, userNav} from './navData';
import {getCompany} from 'services/companies';
import {getCompanyTerminal} from 'services/terminals';
import {getShippingLine} from 'services/shippingLines';
import {
  setSelectedCompany,
  setSelectedTerminal,
  setSelectedShippingLine
} from 'redux/actions/SelectedContext';
import {checkPermission} from 'helpers/checkPermission';
import {loadLookups} from 'redux/actions';

const useStyles = makeStyles(theme => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 20px)'
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)'
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 1px)'
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 1px)'
    }
  },
  sideNavMenu: {
    position: 'relative'
  }
}));

const SideBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const storeData = useSelector(storeData => storeData);
  const {authUser} = storeData.auth;
  const {selectedContext} = storeData;
  const {selectedCompany, selectedTerminal} = selectedContext;

  const [ leftNav, setLeftNav ] = useState([]);
  const [ selectedCompanyId, setSelectedCompanyId ] = useState(null);
  const [ selectedTerminalId, setSelectedTerminalId ] = useState(null);
  const [ shippingLineId, setShippingLineId ] = useState(null);

  useEffect(
    () => {
      (async () => {
        if (authUser.isSuperAdmin) {
          setLeftNav(_.cloneDeep(superAdminNav));
        } else if (authUser.isCompanyAdmin) {
          const company = await getCompany(authUser.adminInCompanyId);
          dispatch(setSelectedCompany(company));
          setLeftNav(_.cloneDeep(superAdminNav));
        } else if (authUser.isShippingLineAdmin) {
          const company = await getCompany(authUser.shippingLineCompanyId);
          dispatch(setSelectedCompany(company));
          setShippingLineId(authUser.adminInShippingLineId);
          const shippingLine = await getShippingLine(
            authUser.shippingLineCompanyId,
            authUser.adminInShippingLineId
          );
          dispatch(setSelectedShippingLine(shippingLine));
          setLeftNav(_.cloneDeep(shippingLineNav));
        } else if (authUser.isTerminalAdmin) {
          setSelectedCompanyId(authUser.terminalCompanyId);
          setSelectedTerminalId(authUser.adminInTerminalId);
          const terminal = await getCompanyTerminal(
            authUser.terminalCompanyId,
            authUser.adminInTerminalId
          );
          const company = await getCompany(authUser.terminalCompanyId);
          dispatch(setSelectedCompany(company));
          dispatch(setSelectedTerminal(terminal));
          dispatch(
            loadLookups({companyId: company.id, terminalId: terminal.id})
          );
          setLeftNav(_.cloneDeep(superAdminNav));
        } else {
          setSelectedCompanyId(
            authUser.terminals[Object.keys(authUser.terminals)[0]].roles[0]
              .Terminal.depotCompanyId
          );
          setSelectedTerminalId(
            authUser.terminals[Object.keys(authUser.terminals)[0]].roles[0]
              .terminalId
          );
          const company = await getCompany(
            authUser.terminals[Object.keys(authUser.terminals)[0]].roles[0]
              .Terminal.depotCompanyId
          );
          dispatch(setSelectedCompany(company));
          const terminal = await getCompanyTerminal(
            authUser.terminals[Object.keys(authUser.terminals)[0]].roles[0]
              .Terminal.depotCompanyId,
            authUser.terminals[Object.keys(authUser.terminals)[0]].roles[0]
              .terminalId
          );
          dispatch(setSelectedTerminal(terminal));
          dispatch(
            loadLookups({companyId: company.id, terminalId: terminal.id})
          );
          setLeftNav(_.cloneDeep(userNav));
        }
      })();
    },
    [ authUser, dispatch ]
  );

  const leftNavigationMenu = items => {
    items = items.filter(item => isAllowed(item));
    return items.map((item, index) => navType(item, index));
  };

  const navType = (item, idx) =>
    item.children ? navDropdown(item, idx) : navItem(item, idx);

  const navItem = (item, index) => {
    if (item.link) {
      item.link = replaceParams(item.link);
    }
    if (item.nameFromStore) {
      item.name =
        selectedContext[item.nameFromStore] &&
        `${selectedContext[item.nameFromStore].name}-Dashboard`;
    }
    return item;
  };

  const navDropdown = (item, key) => {
    const children = navList(item.children);
    if (item.link) {
      item.link = replaceParams(item.link);
    }
    if (item.nameFromStore) {
      item.name =
        selectedContext[item.nameFromStore] &&
        `${selectedContext[item.nameFromStore].name}-Dashboard`;
    }
    item.children = children;
    return item;
  };

  const navList = items => {
    items = items.filter(item => isAllowed(item));
    return items.map((item, index) => navType(item, index));
  };

  const replaceParams = url => {
    url = _.replace(
      url,
      ':selectedCompanyId',
      (selectedCompany && selectedCompany.id) || selectedCompanyId
    );
    url = _.replace(
      url,
      ':selectedTerminalId',
      (selectedTerminal && selectedTerminal.id) || selectedTerminalId
    );
    url = _.replace(url, ':shippingLineId', shippingLineId);
    return url;
  };

  const isAllowed = item => {
    if (!item.allowedTo) {
      return true;
    }

    let showToRolePassed = true;
    let showToParamsPassed = true;
    let showToPermissions = true;

    const hasRole = role => {
      return authUser && authUser[role];
    };

    const hasParam = param => {
      if (param === 'companyId' && selectedContext.selectedCompany) return true;
      if (param === 'shippingLineId' && shippingLineId) return true;
      if (param === 'terminalId' && selectedContext.selectedTerminal)
        return true;
      return false;
    };

    if (item.allowedTo.userType && item.allowedTo.userType.length) {
      showToPermissions = false;
      showToRolePassed = item.allowedTo.userType.some(hasRole);
    }

    if (item.allowedTo.showOnParam && item.allowedTo.showOnParam.length) {
      showToParamsPassed = item.allowedTo.showOnParam.some(hasParam);
    }

    if (item.allowedTo.permissions && item.allowedTo.permissions.length) {
      showToPermissions = checkPermission(authUser, item.allowedTo.permissions);
    }

    return (showToRolePassed || showToPermissions) && showToParamsPassed;
  };

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical
        menuItems={
          authUser.isSuperAdmin ? (
            leftNavigationMenu(_.cloneDeep(leftNav))
          ) : authUser.isCompanyAdmin ? (
            leftNavigationMenu(_.cloneDeep(leftNav))
          ) : authUser.isShippingLineAdmin ? (
            leftNavigationMenu(leftNav)
          ) : authUser.isTerminalAdmin ? (
            leftNavigationMenu(_.cloneDeep(leftNav))
          ) : (
            leftNavigationMenu(_.cloneDeep(leftNav))
          )
        }
      />
    </PerfectScrollbar>
  );
};

export default SideBar;
