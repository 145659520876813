import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import { useHistory } from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box
} from '@material-ui/core';
import { Cancel, ExitToApp } from '@material-ui/icons';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {set as _set} from 'lodash';
import {modalContainerStyles} from 'constants/Styles';
import {updatePassword} from 'redux/actions/Auth';
import IntlMessages from 'utils/IntlMessages';
import {showNotificationMessage} from 'redux/actions/ReactNotification';
import { userSignOut } from '../redux/actions/Auth';
//common modal container styles
const useStyles = makeStyles(theme => modalContainerStyles(theme));
const schema = {newPassword: '', confirmPassword: ''};

const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ model, setModel ] = useState({...schema});
  const [ capitalCase, setCapitalCase ] = useState(false);
  const [ smallCase, setSmallCase ] = useState(false);
  const [ digits, setDigits ] = useState(false);
  const [ specialCharacter, setSpecialCharacter ] = useState(false);
  const [ minLength, setMinLength ] = useState(false);

  const handlePassChange = event => {
    _set(model, event.target.name, event.target.value);
    setModel({...model});
    if (event.target.name === 'newPassword') {
      const capCaseSchema = /.*[A-Z].*/;
      const smallCaseSchema = /.*[a-z].*/;
      const specialCaseSchema = new RegExp('(?=.*[!@#$%^&*])');
      const digitsSchema = /.*[0-9].*/;
      const value = event.target.value;
      if (digitsSchema.test(value)) {
        setDigits(true);
      } else {
        setDigits(false);
      }

      if (capCaseSchema.test(value)) {
        setCapitalCase(true);
      } else {
        setCapitalCase(false);
      }

      if (smallCaseSchema.test(value)) {
        setSmallCase(true);
      } else {
        setSmallCase(false);
      }

      if (specialCaseSchema.test(value)) {
        setSpecialCharacter(true);
      } else {
        setSpecialCharacter(false);
      }

      if (value && value.length > 7) {
        setMinLength(true);
      } else {
        setMinLength(false);
      }
    }
  };

  const update = async () => {
    const schema = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    const data = {
      newPassword: model.newPassword,
      confirmPassword: model.confirmPassword
    };
    if (data && data.newPassword && schema.test(data.newPassword)) {
      if (
        data &&
        data.newPassword &&
        data.confirmPassword &&
        data.newPassword === data.confirmPassword
      ) {
        dispatch(
          updatePassword({
            confirmPassword: data.confirmPassword,
            newPassword: data.newPassword
          })
        );
      } else {
        dispatch(showNotificationMessage('error', 'Password did not matched'));
      }
    } else {
      dispatch(showNotificationMessage('error', 'Password validation error'));
    }
  };

  const logout = () => {
    dispatch(userSignOut(history));
  };

  return (
    <React.Fragment>
      <Dialog
        open={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        keepMounted={false}
        fullWidth={true}
        maxWidth="xs"
      >
        <ValidatorForm onSubmit={update}>
          <Box className={classes.box}>
            <DialogTitle id="cargo-modal-dialog-title">
              {<IntlMessages id="appModule.updatePassword" />}
            </DialogTitle>
            <DialogContent>
              <div className="mb-4">
                <TextValidator
                  label={
                    <span>
                      <IntlMessages id="appModule.newPassword" />*
                    </span>
                  }
                  name="newPassword"
                  type="password"
                  onChange={handlePassChange}
                  value={model.newPassword || ''}
                  validators={[ 'required' ]}
                  errorMessages={[ <IntlMessages id="required.newPassword" /> ]}
                  variant="outlined"
                  size="small"
                  className="w-100"
                />
              </div>
              <div className="mb-4">
                <TextValidator
                  label={
                    <span>
                      <IntlMessages id="appModule.confirmPassword" />*
                    </span>
                  }
                  name="confirmPassword"
                  type="password"
                  onChange={handlePassChange}
                  value={model.confirmPassword || ''}
                  validators={[ 'required' ]}
                  errorMessages={[
                    <IntlMessages id="required.confirmPassword" />
                  ]}
                  variant="outlined"
                  size="small"
                  className="w-100"
                />
              </div>
              <div className="row ml-0 mr-0">
                <div className="col-12 mb-3 text-left ml-3">
                  <ul>
                    <li
                      style={{
                        color: minLength ? 'green' : 'red'
                      }}
                    >
                      <IntlMessages id="userProfile.min8Char" />
                    </li>
                    <li
                      style={{
                        color: capitalCase ? 'green' : 'red'
                      }}
                    >
                      <IntlMessages id="userProfile.min1CapCase" />
                    </li>
                    <li
                      style={{
                        color: smallCase ? 'green' : 'red'
                      }}
                    >
                      <IntlMessages id="userProfile.min1SmallCase" />
                    </li>
                    <li style={{color: digits ? 'green' : 'red'}}>
                      <IntlMessages id="userProfile.min1Digit" />
                    </li>
                    <li
                      style={{
                        color: specialCharacter ? 'green' : 'red'
                      }}
                    >
                      <IntlMessages id="userProfile.min1SpecialChar" />
                    </li>
                  </ul>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color='secondary'
                fullWidth={false}
                onClick={logout}
                startIcon={<ExitToApp />}
              >
                <IntlMessages id={'sidebar.logout'} />
              </Button>
              <Button
                variant="contained"
                color="primary"
                fullWidth={false}
                startIcon={<Cancel />}
                type="submit"
              >
                <IntlMessages id={'update'} />
              </Button>
            </DialogActions>
          </Box>
        </ValidatorForm>
      </Dialog>
    </React.Fragment>
  );
};
export default ResetPassword;
