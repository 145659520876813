import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SET_USER_FROM_TOKEN_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_LOCATION,
  SET_DISABLE_LOGIN,
  SET_REMAINING_LOGIN_POINTS
} from '../../constants/ActionTypes';

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: null,
  userLocationVerified: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        authUser: {
          ...state.authUser,
          user: action.payload
        }
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        userLocationVerified: true
      };
    }
    case SET_USER_FROM_TOKEN_SUCCESS:
      return {
        ...state,
        authUser: action.payload
      };
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
        userLocationVerified: false
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case UPDATE_USER_LOCATION: {
      return {
        ...state,
        userLocationVerified: action.payload
      };
    }
    case SET_DISABLE_LOGIN: {
      return {
        ...state,
        disableLogin: action.payload
      };
    }
    case SET_REMAINING_LOGIN_POINTS: {
      return {
        ...state,
        remainingLoginPoints: action.payload
      };
    }
    default:
      return state;
  }
};
