import React, {useState} from 'react';
import {useHistory, NavLink} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../utils/IntlMessages';
import {useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {Box, alpha} from '@material-ui/core';
import {ExitToApp} from '@material-ui/icons';
import ContentLoader from '../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import AuthWrapper from './AuthWrapper';
import {forgotPassword} from '../../services/authservices';
import {showNotificationMessage} from '../../redux/actions/ReactNotification';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: 1,
      display: 'none'
    }
  },
  authContent: {
    padding: 30,
    width: '55%',
    alignSelf: 'center',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: props => (props.variant === 'default' ? '100%' : '100%'),
      order: 2
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  }
}));

const ForgotPassword = ({variant = 'default', wrapperVariant = 'default'}) => {
  const [ username, setUsername ] = useState('');
  const [ userNameVal, setUserNameVal ] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({variant});
  const history = useHistory();

  const onSubmit = async () => {
    if (!username) {
      setUserNameVal(true);
      return;
    }
    setUserNameVal(false);
    const response = await forgotPassword({username});
    if (response.status && response.status >= 400) {
      dispatch(showNotificationMessage('error', 'username is invalid.'));
      return;
    }
    dispatch(
      showNotificationMessage(
        'success',
        'Email send to your registered address.'
      )
    );
    history.push('/');
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={`bgGrey col1 ${classes.authThumb}`}>
          <CmtImage className="logo" src={'/images/logo.svg'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <div className="formWrapper">
          <Box mb={6} className="text-center">
            <CmtImage src="images/logo.svg" width="200" textAlign="center" />
          </Box>
          <Typography
            component="div"
            variant="h1"
            className={`${classes.titleRoot} text-left`}
          >
            <IntlMessages id="appModule.CDOSAbbr" />
          </Typography>
          <Typography
            component="div"
            variant="h6"
            className={`${classes.titleRoot} mt-2`}
          >
            <IntlMessages id="appModule.forgetPassText" />
          </Typography>
          <Typography
            component="div"
            variant="h3"
            className={`${classes.titleRoot} mt-2`}
          >
            <IntlMessages id="appModule.forgetPasswordText" />
          </Typography>
          <form>
            <Box mb={5}>
              <TextField
                label={<IntlMessages id="appModule.username" />}
                fullWidth
                onChange={event => setUsername(event.target.value)}
                defaultValue={username}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                error={userNameVal}
                helperText={userNameVal && 'Enter username'}
              />
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mb={5}
            >
              <NavLink to="/">
                <IntlMessages id="appModule.goBack" />
              </NavLink>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mb={5}
            >
              <Button
                size="small"
                onClick={onSubmit}
                variant="contained"
                color="primary"
              >
                <ExitToApp />
              </Button>
            </Box>

            <Box />
          </form>
          <ContentLoader />
          <div
            className={`row ${(window.innerHeight < 600 && 'mt-3') || 'mt-5'}`}
          >
            <div
              className={`col-12  text-center ${(window.innerHeight < 600 &&
                'mt-0') ||
                'mt-5'}`}
            >
              &copy; <IntlMessages id={'allRightsReserves'} />
            </div>
          </div>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default ForgotPassword;
