import {
  UPDATE_AUTH_USER,
  UPDATE_LOAD_USER
} from '../../@jumbo/constants/ActionTypes';
import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SET_USER_FROM_TOKEN,
  SET_USER_FROM_TOKEN_SUCCESS,
  UPDATE_PASSWORD,
  SET_DISABLE_LOGIN,
  SET_REMAINING_LOGIN_POINTS
} from '../../constants/ActionTypes';

export const userSignIn = user => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignInSuccess = authUser => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  };
};
export const updatePassword = passwords => {
  return {
    type: UPDATE_PASSWORD,
    payload: passwords
  };
};
export const getCurrentUser = () => {
  return {
    type: SET_USER_FROM_TOKEN
  };
};
export const getCurrentUserSuccess = user => {
  return {
    type: SET_USER_FROM_TOKEN_SUCCESS,
    payload: user
  };
};
export const userSignOut = history => {
  return {
    type: SIGNOUT_USER,
    payload: history
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS
  };
};
export const setInitUrl = url => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};
export const showAuthMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const setAuthUser = user => {
  return {
    type: UPDATE_AUTH_USER,
    payload: user
  };
};

export const updateLoadUser = loading => {
  return {
    type: UPDATE_LOAD_USER,
    payload: loading
  };
};

export const setDisableLogin = message => {
  return {
    type: SET_DISABLE_LOGIN,
    payload: message
  };
};

export const setLoginRemainingPoints = message => {
  return {
    type: SET_REMAINING_LOGIN_POINTS,
    payload: message
  };
};
