import React, {useState, useContext, useEffect} from 'react';
import IntlMessages from '../../utils/IntlMessages';
import {useDispatch} from 'react-redux';
import {
  Button,
  Box,
  alpha,
  Typography,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@material-ui/core';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import {ExitToApp, Check, Visibility, VisibilityOff} from '@material-ui/icons';
import ContentLoader from '../ContentLoader';
import CheckCROModal from 'components/CheckCROStatusModel';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../@coremat/CmtImage';
import {NavLink} from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import {
  setDisableLogin,
  setLoginRemainingPoints,
  userSignIn
} from '../../redux/actions/Auth';
import moment from 'moment-timezone';
import Timer from './Timer';
import {useSelector} from 'react-redux';
import {checkIfIpIsBlocked} from 'services/authservices';
const RECAPTCHA_KEY = process.env.REACT_APP_PUBLIC_RECAPTCHA_KEY;
const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: 1,
      display: 'none'
    }
  },
  authContent: {
    padding: 30,
    width: '55%',
    alignSelf: 'center',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: props => (props.variant === 'default' ? '100%' : '100%'),
      order: 2
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  }
}));

const SignIn = ({variant = 'default', wrapperVariant = 'default'}) => {
  const {theme} = useContext(AppContext);
  const classes = useStyles({variant, theme});
  const dispatch = useDispatch();
  const auth = useSelector(({auth}) => auth);
  const [ username, setUserName ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ open, setOpen ] = useState(false);
  const [ loader, setLoader ] = useState(false);
  const [ disable, setDisable ] = useState(false);
  const [ showPassword, setShowPassword ] = useState(false);
  const [ initialMinute, setInitialMinute ] = useState(0);
  const [ initialSeconds, setInitialSeconds ] = useState(0);
  const [ remainingPoints, setRemainingPoints ] = useState(null);

  useEffect(() => {
    (async () => {
      const checkIfBlocked = await checkIfIpIsBlocked();
      if (checkIfBlocked && checkIfBlocked.retrySecs) {
        dispatch(setDisableLogin(checkIfBlocked.retrySecs));
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (auth.showMessage) {
        setLoader(false);
      }
      if (auth.disableLogin) {
        const time = parseInt(auth.disableLogin);
        const minutes = Math.floor(time / 60);
        const seconds = time - minutes * 60;
        setInitialMinute(minutes);
        setInitialSeconds(seconds);
        setDisable(true);
      }
      if (auth.remainingLoginPoints >= 0) {
        setRemainingPoints(auth.remainingLoginPoints);
      }
    },
    [ auth ]
  );

  const resetTimer = () => {
    setDisable(false);
    setInitialMinute(0);
    setInitialSeconds(0);
    dispatch(setDisableLogin(null));
    dispatch(setLoginRemainingPoints(5));
  };

  const onSubmit = () => {
    setLoader(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(RECAPTCHA_KEY, {action: 'submit'})
        .then(recaptchaToken => {
          let latitude = null;
          let longitude = null;
          navigator.geolocation.getCurrentPosition(
            function(position){
              latitude = position.coords.latitude;
              longitude = position.coords.longitude;
              dispatch(
                userSignIn({
                  username,
                  password,
                  latitude,
                  longitude,
                  recaptchaToken
                })
              );
            },
            function(){
              dispatch(
                userSignIn({
                  username,
                  password,
                  latitude,
                  longitude,
                  recaptchaToken
                })
              );
            }
          );
        });
    });
  };
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = function(){
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`,
      function(){
        console.log('Script loaded!');
      }
    );
  }, []);

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={`bgGrey col1 ${classes.authThumb}`}>
          {/* <CmtImage className="logo" src={'/images/logo.svg'} /> */}
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <div className="formWrapper">
          {disable && (
            <div className="signInBlockOverlay">
              <Timer
                initialMinute={initialMinute}
                initialSeconds={initialSeconds}
                reset={resetTimer}
              />
            </div>
          )}
          <Box mb={6} className="text-center">
            <CmtImage src="images/logo.svg" width="130" textAlign="center" />
          </Box>
          <Typography
            component="div"
            variant="h1"
            className={`${classes.titleRoot} `}
          >
            <IntlMessages id="signIn.containerDepotOperatingSystem" />
          </Typography>
          <Typography
            component="div"
            variant="h3"
            className={`${classes.titleRoot} mt-2`}
          >
            <IntlMessages id="appModule.login" />
          </Typography>
          {!disable &&
          remainingPoints != null && (
            <div className="text-right">
              <small className="text-danger">
                You have {remainingPoints} attempts remaining.
              </small>
            </div>
          )}
          <div className={disable ? 'signInDisable' : ''}>
            <ValidatorForm onSubmit={onSubmit}>
              <Box mb={2}>
                <TextValidator
                  label={<IntlMessages id="appModule.username" />}
                  fullWidth
                  onChange={event => setUserName(event.target.value)}
                  value={username}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  validators={[ 'required' ]}
                  errorMessages={[ 'Username is required' ]}
                />
              </Box>
              <Box mb={2}>
                <TextValidator
                  type={showPassword ? 'text' : 'password'}
                  label={<IntlMessages id="appModule.password" />}
                  fullWidth
                  onChange={event => setPassword(event.target.value)}
                  value={password}
                  margin="normal"
                  variant="outlined"
                  className={classes.textFieldRoot}
                  validators={[ 'required' ]}
                  errorMessages={[ 'Password is required' ]}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mb={1}
              >
                <Box component="p" fontSize={{xs: 12, sm: 16}}>
                  <NavLink to="/forgot_password">
                    <IntlMessages id="appModule.forgotPassword" />
                  </NavLink>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mb={2}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color={loader ? '' : 'primary'}
                  size="small"
                  startIcon={
                    loader ? <CircularProgress size="15px" /> : <ExitToApp />
                  }
                  style={{width: '110px'}}
                  disabled={loader}
                >
                  <IntlMessages id="appModule.signIn" />
                </Button>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mb={2}
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={<Check />}
                  style={{width: '110px'}}
                  onClick={() => setOpen(true)}
                >
                  <IntlMessages id="appModule.cro" />
                </Button>
              </Box>
            </ValidatorForm>
          </div>
          {open && <CheckCROModal onClose={() => setOpen(false)} />}
          <ContentLoader />
          <div
            className={`row ${(window.innerHeight < 600 && 'mt-3') || 'mt-5'}`}
          >
            <div
              className={`col-12  text-center ${(window.innerHeight < 600 &&
                'mt-0') ||
                'mt-5'}`}
            >
              &copy; {moment().get('year')}{' '}
              <IntlMessages id={'allRightsReserves'} />
            </div>
          </div>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
