import {ON_LOOKUPS_LOAD, RESET_LOOKUPS} from '../../constants/ActionTypes';

const INIT_STATE = {
  containerTypes: [],
  containerSizes: [],
  statuses: [],
  shippingLines: [],
  grades: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ON_LOOKUPS_LOAD: {
      const payload = action.payload || {};
      return {
        ...state,
        containerTypes: payload.containerTypes,
        containerSizes: payload.containerSizes,
        statuses: payload.statuses,
        shippingLines: payload.shippingLines,
        grades: payload.grades
      };
    }
    case RESET_LOOKUPS: {
      return {
        ...state,
        ...INIT_STATE
      };
    }
    default:
      return state;
  }
};
