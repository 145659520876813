import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CmtMediaObject from '../../../../../../@coremat/CmtMediaObject';
import CmtAvatar from '../../../../../../@coremat/CmtAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {alpha} from '@material-ui/core';
import {markAsRead} from 'services/terminals';
import {getCompanyId, getTerminalId} from 'helpers/context';
import {showNotificationMessage} from 'redux/actions/ReactNotification';

const useStyles = makeStyles(theme => ({
  feedItemRoot: {
    padding: '10px 0',
    position: 'relative',
    borderBottom: `1px solid ${alpha(theme.palette.common.dark, 0.035)}`,
    '& .Cmt-media-object': {
      alignItems: 'center'
    },
    '& .Cmt-media-image': {
      alignSelf: 'flex-start',
      width: 56
    },
    '& .Cmt-media-body': {
      width: 'calc(100% - 56px)',
      flex: 'inherit'
    }
  },
  titleRoot: {
    letterSpacing: 0.25,
    marginBottom: 6,
    cursor: 'pointer'
  }
}));

const getContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" ml={1}>
      {item.title}
    </Box>
  </Typography>
);

const getPostContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.title}
    </Box>
    <Box component="span" ml={1}>
      {item.title}
    </Box>
    <Box component="span" ml={1}>
      {item.metaData.post.type}
    </Box>
  </Typography>
);

const getSharedContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="primary.main">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has shared
    </Box>
    <Box component="span" ml={1} color="primary.main">
      {`${item.metaData.post.owner.name}'s`}
    </Box>
    <Box component="span" ml={1}>
      post.
    </Box>
  </Typography>
);

const getBirthdayContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.user.name}
    </Box>
    <Box component="span" ml={1}>
      has birthday today.
    </Box>
  </Typography>
);

const getInvitationContent = (item, classes) => (
  <Typography component="div" variant="h5" className={classes.titleRoot}>
    <Box component="span" color="blue">
      {item.metaData.user.name}
    </Box>
    <Box component="span" ml={1}>
      has sent you a group invitation for
    </Box>
    <Box component="span" color="blue" ml={1}>
      {item.metaData.group.name}
    </Box>
  </Typography>
);

const NotificationItem = ({item, loadData}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authUser = useSelector(({auth}) => auth.authUser);
  const selectedContext = useSelector(({selectedContext}) => selectedContext);
  const companyId = getCompanyId(authUser, selectedContext);
  const terminalId = getTerminalId(authUser, selectedContext);

  const getTitle = (item, classes) => {
    switch (item.type) {
      case 'POSTING':
        return getPostContent(item, classes);
      case 'SHARED_POST':
        return getSharedContent(item, classes);
      case 'INVITATION':
        return getInvitationContent(item, classes);
      case 'BIRTHDAY':
        return getBirthdayContent(item, classes);
      default:
        return getContent(item, classes);
    }
  };

  const getSubTitle = () => (
    <Box display="flex" alignItems="center" fontSize={12} color="text.disabled">
      <Box ml={2}>{item.time}</Box>
    </Box>
  );

  const markNotificationAsRead = async () => {
    const result = await markAsRead(companyId, terminalId, item.id, {
      isRead: true
    });
    if (result.resStatus >= 400) {
      dispatch(showNotificationMessage('error', result.message));
    } else {
    }
  };

  return (
    <Box className={classes.feedItemRoot}>
      <CmtMediaObject
        avatarPos="center"
        avatar={<CmtAvatar size={40} src={item.image} alt="avatar-image" />}
        title={getTitle(item, classes)}
        subTitle={getSubTitle()}
        onClick={() => markNotificationAsRead()}
      />
    </Box>
  );
};

export default NotificationItem;
