import {intersection as _intersection} from 'lodash';
export const checkPermission = (authUser, permission) => {
  let {terminals = {}} = authUser;
  terminals = Object.values(terminals);
  const terminalPermissions =
    terminals[0] && terminals[0].permissions && terminals[0].permissions;
  if (
    authUser.isSuperAdmin ||
    authUser.isCompanyAdmin ||
    authUser.isShippingLineAdmin ||
    authUser.isTerminalAdmin
  ) {
    return true;
  } else if (
    permission &&
    _intersection(terminalPermissions, permission).length > 0
  ) {
    return true;
  } else {
    return false;
  }
};
