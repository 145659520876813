import {all, put, takeEvery} from 'redux-saga/effects';
import {UPDATE_USER} from 'constants/ActionTypes';
import {updateUser} from 'services/user';
import {updateUserSuccess} from 'redux/actions/User';
import {showNotificationMessage} from 'redux/actions/ReactNotification';

function* changeUserDetails({payload}){
  try {
    let user = yield updateUser(payload);
    if (user.message) {
      yield put(showNotificationMessage('error', user.message));
    } else {
      yield put(showNotificationMessage('success', 'User is updated'));
      yield put(updateUserSuccess(user));
    }
  } catch (error) {
    yield put(showNotificationMessage('error', error));
  }
}

export function* UpdateUserDetail(){
  yield takeEvery(UPDATE_USER, changeUserDetails);
}

export default function* rootSaga(){
  yield all([ UpdateUserDetail() ]);
}
