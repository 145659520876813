//Auth const
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const SET_USER_FROM_TOKEN = 'set_user_from_token';
export const SET_USER_FROM_TOKEN_SUCCESS = 'set_user_from_token_success';
export const SET_USER_FROM_TOKEN_FAILURE = 'set_user_from_token_failure';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const ON_SHOW_LOADER = 'on_show_loader';
export const SET_DISABLE_LOGIN = 'set_disable_login';
export const SET_REMAINING_LOGIN_POINTS = 'set_remaining_login_points';
export const REFRESH_APP = 'refresh_app';

// User
export const UPDATE_USER = 'update_user';
export const UPDATE_USER_SUCCESS = 'update_user_success';
export const UPDATE_PASSWORD = 'update_password';
export const UPDATE_USER_LOCATION = 'update_user_location';

//Lookup const
export const LOAD_LOOKUPS = 'LOAD_LOOKUPS';
export const ON_LOOKUPS_LOAD = 'ON_LOOKUPS_LOAD';
export const ON_UPDATE_CURRENCY = 'ON_UPDATE_CURRENCY';
export const RESET_LOOKUPS = 'RESET_LOOKUPS';

////Notifications
export const SHOW_NOTIFICATION_MESSAGE = 'SHOW_NOTIFICATION_MESSAGE';
export const HIDE_NOTIFICATION_MESSAGE = 'HIDE_NOTIFICATION_MESSAGE';

//Popup Alerts
export const SHOW_POPUP_ALERT = 'SHOW_POPUP_ALERT';
export const HIDE_POPUP_ALERT = 'HIDE_POPUP_ALERT';

///SELECTED COMPANY
export const SET_SELECTED_COMPANY = 'selected_company';
export const SET_SELECTED_TERMINAL = 'selected_terminal';
export const SET_SELECTED_SHIPPING_LINE = 'selected_shipping_line';
export const RESET_TERMINAL = 'reset_terminal';

///// countries
export const FETCH_ALL_COUNTRIES = 'fetch_all_countries';
export const FETCH_ALL_COUNTRIES_SUCCESS = 'fetch_all_countries-success';
