import {
  FETCH_ALL_COUNTRIES,
  FETCH_ALL_COUNTRIES_SUCCESS,
  SHOW_MESSAGE
} from '../../constants/ActionTypes';

export const getCountries = () => {
  return {
    type: FETCH_ALL_COUNTRIES
  };
};

export const getCountriesSuccess = countries => {
  return {
    type: FETCH_ALL_COUNTRIES_SUCCESS,
    payload: countries
  };
};

export const showCountriesMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};
