import {all, fork, put, takeEvery} from 'redux-saga/effects';

import {showNotificationMessage} from '../actions/ReactNotification';
import {onLoadLookups} from '../actions/Lookup';

import {getLookups as getLookupsRequest} from '../../services/Lookup';
import {LOAD_LOOKUPS} from '../../constants/ActionTypes';

function* getLookups({payload}){
  try {
    const lookups = yield getLookupsRequest(payload);
    if (lookups && lookups.message) {
    } else {
      yield put(onLoadLookups(lookups));
    }
  } catch (error) {
    yield put(showNotificationMessage('error', error));
  }
}

export function* loadAllLookups(){
  yield takeEvery(LOAD_LOOKUPS, getLookups);
}

export default function* rootSaga(){
  yield all([ fork(loadAllLookups) ]);
}
