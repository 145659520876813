import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  alpha,
  Hidden,
  IconButton,
  withWidth,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import SettingsIcon from '@material-ui/icons/Settings';
import CmtDropdownMenu from '../../../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../../../@coremat/CmtAvatar';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AuhMethods } from '../../../../../../services/auth/index';
import { CurrentAuthMethod } from '../../../../../constants/AppConstants';
import PersonIcon from '@material-ui/icons/Person';
import LanguageIcon from '@material-ui/icons/Translate';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { makeStyles } from '@material-ui/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import IntlMessages from 'utils/IntlMessages';
import clsx from 'clsx';
import SidebarToggleHandler from '../../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import LayoutContext from '../../../../../../@coremat/CmtLayouts/Vertical/LayoutContext/LayoutContext';
import Logo from '../../../partials/Logo';
import ActionBarDrawer from './ActionBarDrawer';
import AppContext from '../../../../contextProvider/AppContextProvider/AppContext';
import { userSignOut } from '../../../../../../redux/actions/Auth';
import {
  setSelectedCompany,
  setSelectedTerminal
} from '../../../../../../redux/actions/SelectedContext';
import {
  viewedNotifications,
  getUnreadNotificationCount
} from 'services/terminals';
import { getCompanyId, getTerminalId } from 'helpers/context';
import { resetLookups } from 'redux/actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '10px 24px 10px 15px',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      padding: '16px 5px'
    },
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  iconBtn: {
    position: 'relative',
    color: alpha(theme.palette.common.white, 0.9),
    '&:hover, &:focus': {
      color: theme.palette.common.white
    }
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20
  },
  version: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 14
  }
}));

const actionsList = [
  {
    icon: <PersonIcon />,
    label: <IntlMessages id={'sidebar.profile'} />,
    id: 'profile'
  },
  {
    icon: <ExitToAppIcon />,
    label: <IntlMessages id={'sidebar.logout'} />,
    id: 'logout'
  }
];

const ActionSideBar = ({ setSidebarWidth, width }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDrawerOpen, setDrawerStatus] = useState(false);
  const [activeOption, setActiveOption] = useState(null);
  const { isOpen, setOpen } = useContext(LayoutContext);
  const { sidebarSize, socketInstance } = useContext(AppContext);
  const history = useHistory();
  const authUser = useSelector(({ auth }) => auth.authUser);
  const selectedContext = useSelector(({ selectedContext }) => selectedContext);
  const companyId = getCompanyId(authUser, selectedContext);
  const terminalId = getTerminalId(authUser, selectedContext);
  const [count, setCountCount] = useState(0);

  useEffect(() => {
    // if(socketInstance){
    //   socketInstance.on('all-notifications-count', data => {
    //     setCountCount(data.count);
    //   });
    // }
    getUnreadNotificationCountAfterInterval();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isOpen && (width === 'lg' || width === 'xl')) {
        setSidebarWidth(0);
      } else {
        setSidebarWidth(sidebarSize);
      }
    },
    [isOpen, width, setSidebarWidth, sidebarSize]
  );

  useEffect(
    () => {
      if (activeOption && !isDrawerOpen) {
        setDrawerStatus(true);
      }
    },
    [activeOption, isDrawerOpen]
  );

  const getUnreadNotificationCountAfterInterval = async () => {
    if (
      authUser.isSuperAdmin ||
      authUser.isCompanyAdmin ||
      authUser.isShippingLineAdmin
    ) {
      return;
    } else {
      const notificationsCount = await getUnreadNotificationCount(
        companyId,
        terminalId
      );
      setCountCount(notificationsCount);
    }
  };

  const viewedNotificationsCount = async () => {
    const viewedNotification = await viewedNotifications(companyId, terminalId);
    setCountCount(viewedNotification);
  };

  const onItemClick = item => {
    if (item.id === 'logout') {
      if (socketInstance) socketInstance.disconnect();
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      dispatch(userSignOut(history));
      dispatch(setSelectedCompany(null));
      dispatch(setSelectedTerminal(null));
      dispatch(resetLookups());
    } else if (item.id === 'profile') {
      history.push('/user-profile');
    }
  };

  const onIconClick = option => {
    setActiveOption(option);
    if (option === 'notifications') {
      viewedNotificationsCount();
    }
  };

  const onDrawerClose = () => {
    setDrawerStatus(false);
    setActiveOption(null);
  };

  return (
    <Box className={clsx(classes.root, 'actionSidebar')}>
      <Box display="flex" alignItems="center">
        <SidebarToggleHandler className={classes.iconBtn}>
          {isOpen && (width === 'lg' || width === 'xl') && <CloseIcon />}
        </SidebarToggleHandler>
        <Hidden lgUp>
          <Logo color="white" ml={{ xs: 3, sm: 6 }} />
        </Hidden>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'row', lg: 'column' }}
        ml={{ xs: 'auto', lg: 'unset' }}
      >
        <IconButton
          className={classes.iconBtn}
          onClick={() => onIconClick('search')}
        >
          <SearchIcon />
        </IconButton>

        <IconButton
          className={classes.iconBtn}
          onClick={() => onIconClick('language')}
        >
          <LanguageIcon />
        </IconButton>

        {!authUser.isSuperAdmin &&
          !authUser.isCompanyAdmin &&
          !authUser.isShippingLineAdmin && (
            <IconButton
              className={classes.iconBtn}
              onClick={() => onIconClick('notifications')}
            >
              <Badge badgeContent={count} classes={{ badge: classes.counterRoot }}>
                <NotificationsIcon />
              </Badge>
            </IconButton>
          )}

        {isOpen &&
          (width === 'lg' || width === 'xl') && (
            <IconButton
              className={classes.iconBtn}
              onClick={() => setOpen(!isOpen)}
            >
              <MoreVertIcon />
            </IconButton>
          )}
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'row', lg: 'column' }}
        mt={{ xs: 'unset', lg: 'auto' }}
      >
        <IconButton
          className={classes.iconBtn}
          onClick={() => onIconClick('settings')}
        >
          <SettingsIcon />
        </IconButton>

        <CmtDropdownMenu
          onItemClick={onItemClick}
          TriggerComponent={
            <CmtAvatar
              src={
                authUser &&
                authUser.user &&
                authUser.user.picture &&
                authUser.user.picture.path
              }
            />
          }
          items={actionsList}
        />
      </Box>

      <ActionBarDrawer
        activeOption={activeOption}
        open={isDrawerOpen}
        onDrawerClose={onDrawerClose}
        onIconClick={onIconClick}
        count={count}
      />
    </Box>
  );
};

export default withWidth()(ActionSideBar);
