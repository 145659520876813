import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/dist/react-notifications.css';

import {hideNotificationMessage} from '../redux/actions/ReactNotification';

const ReactNotification = () => {
  const {showMessage, type, message} = useSelector(
    ({reactNotification}) => reactNotification
  );
  const dispatch = useDispatch();

  useEffect(() => {}, [ message, showMessage ]);

  const createNotification = (type, message) => {
    switch (type) {
      case 'info':
        NotificationManager.info(message);
        break;
      case 'success':
        NotificationManager.success(message, '', 1000);
        break;
      case 'warning':
        NotificationManager.warning(message, '', 1000);
        break;
      case 'error':
        NotificationManager.error(message, '', 1000);
        break;
      default:
        NotificationManager.info(message, '', 1000);
    }
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideNotificationMessage());
      }, 1000);
    }
  };

  return (
    <div>
      {showMessage && createNotification(type, message)}
      <NotificationContainer />
    </div>
  );
};
export default ReactNotification;
