import {all, fork, put, takeEvery} from 'redux-saga/effects';

import {
  signIn,
  signOutMe,
  userContext,
  updateExpiredPassword
} from '../../services/authservices.js';

import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SET_USER_FROM_TOKEN,
  UPDATE_PASSWORD
} from 'constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignOutSuccess,
  userSignOut,
  getCurrentUserSuccess,
  setDisableLogin,
  setLoginRemainingPoints
} from '../actions/Auth';
import {showNotificationMessage} from '../actions/ReactNotification';
import {showPopupAlert} from '../actions/PopupAlert';

function* signInUserWithEmailPassword({payload}){
  const {username, password, latitude, longitude, recaptchaToken} = payload;
  try {
    const signInUser = yield signIn(
      username,
      password,
      latitude,
      longitude,
      recaptchaToken
    );
    if (signInUser.message && signInUser.isOutsideFence) {
      yield put(
        showPopupAlert(
          'Location Verification Failed',
          'You are not authorized to use outside Depot',
          'text-red'
        )
      );
    } else if (signInUser.message) {
      if (signInUser.retrySecs) {
        yield put(setDisableLogin(signInUser.retrySecs));
      }
      if (signInUser.consume && signInUser.consume.remainingPoints >= 0) {
        yield put(setLoginRemainingPoints(signInUser.consume.remainingPoints));
      }
      yield put(showAuthMessage(signInUser.message));
      yield put(showNotificationMessage('error', signInUser.message));
    } else {
      localStorage.setItem('x-ctos-token', signInUser.token);
      yield put(userSignInSuccess(signInUser));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
    yield put(showNotificationMessage('error', error));
  }
}

function* updatePasswordOnExpired({payload}){
  try {
    const update = yield updateExpiredPassword(payload);
    if (update.message) {
      yield put(showNotificationMessage('error', update.message));
    } else {
      yield put(
        showNotificationMessage('success', 'Password updated successfully')
      );
      yield put(userSignOut(null));
    }
  } catch (error) {
    alert(error);
    yield put(showAuthMessage(error));
  }
}

function* signOut({payload}){
  try {
    const signOutUser = yield signOutMe();
    if (signOutUser.message) {
      yield put(showAuthMessage(signOutUser.message));
    } else {
      yield put(setLoginRemainingPoints(null));
      localStorage.removeItem('x-ctos-token');
      yield put(userSignOutSuccess(signOutUser));
      payload.push('/');
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* signInUser(){
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser(){
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* updatePassword(){
  yield takeEvery(UPDATE_PASSWORD, updatePasswordOnExpired);
}

function* setUserToken(){
  try {
    let user = yield userContext();
    if (!(user instanceof Object)) {
    } else {
      yield put(getCurrentUserSuccess(user));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* getUserContext(){
  yield takeEvery(SET_USER_FROM_TOKEN, setUserToken);
}

export default function* rootSaga(){
  yield all([
    fork(signInUser),
    fork(updatePassword),
    fork(getUserContext),
    fork(signOutUser)
  ]);
}
