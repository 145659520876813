import React from 'react';
import {useState, useEffect} from 'react';

const Timer = ({initialMinute = 1, initialSeconds = 0, reset}) => {
  const [ minutes, setMinutes ] = useState(initialMinute);
  const [ seconds, setSeconds ] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          reset();
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  if (minutes === 0 && seconds === 0) {
    return null;
  } else {
    return (
      <React.Fragment>
        <h1
          style={{fontSize: '10px', fontWeight: '600'}}
          className="text-danger"
        >
          You are blocked for 5 wrong attempts.
        </h1>
        <h1
          class="mr-2 mt-2"
          style={{fontSize: '40px'}}
          className="text-danger"
        >
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h1>
      </React.Fragment>
    );
  }
};

export default Timer;
