import React, {useEffect, useRef, useContext} from 'react';
import {Route, Switch} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {isEmpty as _isEmpty, find as _find} from 'lodash';
import 'react-big-calendar/lib/less/styles.less';
import '../styles/bootstrap.scss';
import '../styles/app.scss';
import '../styles/app-rtl.scss';

import SignIn from 'components/authComponents/SignIn';
import ForgetPassword from 'components/authComponents/ForgotPassword';
import Polices from 'components/authComponents/polices';
import ResetPassword from 'components/authComponents/ResetPassword';
import MainAppRoutes from 'app/index';
import {userContext} from '../services/authservices';
import {userSignInSuccess} from '../redux/actions/Auth';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import {languageData} from 'constants/LanguageData';

const App = () => {
  const {updateLocale, setSocketInstance} = useContext(AppContext);
  const auth = useSelector(({auth}) => auth);
  const authUser = useRef((auth && auth.authUser) || null);
  const dispatch = useDispatch();
  const token = localStorage.getItem('x-ctos-token') || null;

  useEffect(
    () => {
      const checkAuthUser = async () => {
        if (!_isEmpty(token) && _isEmpty(authUser.current)) {
          authUser.current = await userContext();
          if (!_isEmpty(authUser.current)) {
            dispatch(userSignInSuccess(authUser.current));
          }
        } else if (!_isEmpty(auth) && !_isEmpty(auth.authUser)) {
          const languageLocale = _find(
            languageData,
            lan => lan.languageId === auth.authUser.user.languageId
          );
          if (languageLocale) {
            updateLocale(languageLocale);
          }
        }
      };
      checkAuthUser();
      return () => {
        setSocketInstance(null);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ auth, dispatch, token ]
  );

  return (
    <div className="app-main">
      <Switch>
        <Route
          path="/reset_password/:passwordResetToken"
          component={ResetPassword}
        />
        <Route path="/polices" component={Polices} />
        <Route path="/forgot_password" component={ForgetPassword} />
        {token === null &&
        authUser.current === null && <Route path="/" component={SignIn} />}
        {authUser.current && <Route path={'/'} component={MainAppRoutes} />}
      </Switch>
    </div>
  );
};

export default App;
