import {all, put, takeEvery} from 'redux-saga/effects';
import {FETCH_ALL_COUNTRIES} from '../../constants/ActionTypes';
import {getCountries} from '../../services/countries';
import {getCountriesSuccess, showCountriesMessage} from '../actions/Country';

function* fetchCountries(){
  try {
    let countries = yield getCountries();
    if (countries.message) {
      yield put(showCountriesMessage(countries.message));
    } else {
      yield put(getCountriesSuccess(countries));
    }
  } catch (error) {
    yield put(showCountriesMessage(error));
  }
}

export function* getAllCountries(){
  yield takeEvery(FETCH_ALL_COUNTRIES, fetchCountries);
}

export default function* rootSaga(){
  yield all([ getAllCountries() ]);
}
