import {
  SHOW_NOTIFICATION_MESSAGE,
  HIDE_NOTIFICATION_MESSAGE
} from 'constants/ActionTypes';

const INIT_STATE = {
  message: '',
  showMessage: false,
  type: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        message: action.payload.message,
        showMessage: true,
        type: action.payload.type
      };
    }
    case HIDE_NOTIFICATION_MESSAGE: {
      return {
        ...state,
        message: '',
        showMessage: false,
        type: ''
      };
    }
    default: {
      return state;
    }
  }
};
