import {
  LOAD_LOOKUPS,
  ON_LOOKUPS_LOAD,
  ON_UPDATE_CURRENCY,
  RESET_LOOKUPS
} from '../../constants/ActionTypes';

export const loadLookups = params => {
  return {
    type: LOAD_LOOKUPS,
    payload: params
  };
};

export const onLoadLookups = lookups => {
  return {
    type: ON_LOOKUPS_LOAD,
    payload: lookups
  };
};

export const resetLookups = () => {
  return {
    type: RESET_LOOKUPS
  };
};

export const onUpdateCurrency = currencies => {
  return {
    type: ON_UPDATE_CURRENCY,
    payload: currencies
  };
};
