import {
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  SHOW_MESSAGE,
  UPDATE_USER_LOCATION
} from 'constants/ActionTypes';

export function updateUser(user){
  return {
    type: UPDATE_USER,
    payload: user
  };
}

export function updateUserSuccess(user){
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user
  };
}

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const updateLocation = location => {
  return {
    type: UPDATE_USER_LOCATION,
    payload: location
  };
};
