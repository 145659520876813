import {SHOW_POPUP_ALERT, HIDE_POPUP_ALERT} from 'constants/ActionTypes';

const INIT_STATE = {
  showPopup: false,
  title: '',
  message: '',
  textColor: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_POPUP_ALERT: {
      return {
        ...state,
        showPopup: true,
        title: action.payload.title,
        message: action.payload.message,
        textColor: action.payload.textColor
      };
    }
    case HIDE_POPUP_ALERT: {
      return {
        ...state,
        showPopup: false,
        title: '',
        message: '',
        textColor: ''
      };
    }
    default: {
      return state;
    }
  }
};
