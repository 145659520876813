import {store} from '../MainApp';
import {showRefreshAppAlert} from '../redux/actions/RefreshApp';
const baseUrl = '/api';

const headers = () => {
  const xToken = localStorage.getItem('x-ctos-token');
  const localAppVersion = localStorage.getItem('app_version');
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    authorization: `Bearer ${xToken}`,
    ...(localAppVersion &&
    localAppVersion !== 'null' && {
      'App-Version': localAppVersion
    })
  };
};

const isValidToken = () => {
  const xToken = localStorage.getItem('x-ctos-token');
  return !!xToken;
};

const checkAppVersion = async res => {
  const {refreshApp} = store.getState();
  const serverAppVersion = res.headers.get('App-Version');
  const localAppVersion = localStorage.getItem('app_version');
  if (
    localAppVersion &&
    localAppVersion !== 'null' &&
    localAppVersion !== serverAppVersion
  ) {
    store.dispatch(showRefreshAppAlert());
  } else if (
    !refreshApp.refreshApp &&
    (!localAppVersion || localAppVersion === 'null')
  ) {
    localStorage.setItem('app_version', serverAppVersion);
  }
};

const defaultResponse = async res => {
  const status = res.status;
  await checkAppVersion(res);
  res = await res.json();
  if (status === 403) {
    localStorage.removeItem('user_id');
    localStorage.removeItem('x-ctos-token');
    window.location.href = '/';
    return {
      resStatus: status,
      message: 'Your token has expired, please login again.'
    };
  }
  if (status >= 400) {
    const message = res.message;
    return {resStatus: status, message: message};
  }
  return res;
};

const get = async api => {
  if (!isValidToken()) {
    return null;
  }
  const res = await fetch(api, {
    headers: headers(),
    credentials: 'include',
    method: 'GET'
  });
  return defaultResponse(res);
};

const getDownload = async api => {
  if (!isValidToken()) {
    return null;
  }
  const res = await fetch(api, {
    headers: headers(),
    credentials: 'include',
    method: 'GET'
  });
  return res;
};

const isValidCro = api => {
  return fetch(api, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    method: 'GET'
  }).then(res => defaultResponse(res));
};

const post = (api, data) => {
  if (!isValidToken()) {
    return null;
  }
  return fetch(api, {
    headers: headers(),
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(data)
  }).then(res => defaultResponse(res));
};

const put = (api, data) => {
  if (!isValidToken()) {
    return null;
  }
  return fetch(api, {
    headers: headers(),
    credentials: 'include',
    method: 'PUT',
    body: JSON.stringify(data)
  }).then(res => defaultResponse(res));
};

const destroy = api => {
  if (!isValidToken()) {
    return null;
  }
  return fetch(api, {
    headers: headers(),
    credentials: 'include',
    method: 'DELETE'
  }).then(res => defaultResponse(res));
};

const toggle = api => {
  if (!isValidToken()) {
    return null;
  }
  return fetch(api, {
    headers: headers(),
    credentials: 'include',
    method: 'PUT'
  }).then(res => defaultResponse(res));
};

export {
  headers,
  get,
  post,
  put,
  destroy,
  toggle,
  baseUrl,
  isValidCro,
  getDownload
};
