import {baseUrl, get, post, put, destroy, toggle} from './defaults';

export const getCompanies = params => {
  let queryString = new URLSearchParams(params).toString();
  return get(`${baseUrl}/companies?${queryString}`);
};

export const getCompany = companyId => {
  return get(`${baseUrl}/companies/${companyId}`);
};

export const saveCompany = (company, companyId = '') => {
  const type = (companyId && put) || post;
  return type(`${baseUrl}/companies/${companyId}`, company);
};

export const editCompany = (companyId, company) => {
  return put(`${baseUrl}/companies/${companyId}`, company);
};

export const deleteCompany = companyId => {
  return destroy(`${baseUrl}/companies/${companyId}`);
};

export const activeCompanyCount = () => {
  return get(`${baseUrl}/companies/count`);
};

export const toggleCompanyStatus = companyId => {
  return toggle(`${baseUrl}/companies/${companyId}/toggle-status`);
};

export const getCompanyById = companyId => {
  return get(`${baseUrl}/companies/${companyId}`);
};

export const companyVsTerminalCount = () => {
  return get(`${baseUrl}/companies/terminals/grouped/count`);
};

export const companyVsLoginCount = () => {
  return get(`${baseUrl}/companies/login/activity`);
};
