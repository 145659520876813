import {REFRESH_APP} from 'constants/ActionTypes';

const INIT_STATE = {
  refreshApp: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case REFRESH_APP: {
      return {
        ...state,
        refreshApp: true
      };
    }
    default: {
      return state;
    }
  }
};
