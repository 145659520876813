import {
  SET_SELECTED_COMPANY,
  SET_SELECTED_TERMINAL,
  SET_SELECTED_SHIPPING_LINE,
  RESET_TERMINAL
} from '../../constants/ActionTypes';

const INIT_STATE = {
  selectedCompany: null,
  selectedTerminal: null,
  selectedShippingLine: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SELECTED_COMPANY: {
      return {
        ...state,
        selectedCompany: action.payload
      };
    }
    case SET_SELECTED_TERMINAL: {
      return {
        ...state,
        selectedTerminal: action.payload
      };
    }
    case SET_SELECTED_SHIPPING_LINE: {
      return {
        ...state,
        selectedShippingLine: action.payload
      };
    }
    case RESET_TERMINAL: {
      return {
        ...state,
        selectedTerminal: null
      };
    }

    default:
      return state;
  }
};
