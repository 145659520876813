import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';

const ConfirmationDialog = ({
  onClose,
  onAccept,
  title,
  message,
  visible = true,
  children,
  onClickEvent
}) => {
  const [ isOpen, setOpen ] = useState(false);

  const handleRequestClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const open = async () => {
    if (onClickEvent) {
      await onClickEvent();
    }
    setOpen(true);
  };

  const accept = () => {
    setOpen(false);
    onAccept();
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen && visible}
        onClose={handleRequestClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleRequestClose}
            color="secondary"
          >
            {<IntlMessages id="disagree" />}
          </Button>
          <Button variant="contained" onClick={accept} color="primary">
            {<IntlMessages id="agree" />}
          </Button>
        </DialogActions>
      </Dialog>
      {children &&
        React.cloneElement(children, {
          onClick: () => open()
        })}
    </React.Fragment>
  );
};

export default ConfirmationDialog;
