import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Error404 from 'components/NotFound';
import PageLoader from 'components/PageComponents/PageLoader';
import ResetPassword from 'components/ResetPassword';
import FenceWarning from 'components/FenceWarning';

const DashboardRoutes = lazy(() => import('./routes/dashboard'));
const ISORoutes = lazy(() => import('./routes/iso'));
const Profile = lazy(() => import('./routes/user-profile'));
const CompanyRoutes = lazy(() => import('./routes/companies/'));

const MainAppRoutes = () => {
  const match = useRouteMatch();
  const { authUser } = useSelector(({ auth }) => auth);

  return (
    <Suspense fallback={<PageLoader />}>
      {authUser && !authUser.isSuperAdmin &&
        authUser.passwordResetRequired && <ResetPassword />}
      <FenceWarning />
      <Switch>
        <Route exact path="/" component={DashboardRoutes} />
        <Route path={`${match.url}isoCodes`} component={ISORoutes} />
        <Route path={`${match.url}user-profile`} component={Profile} />
        <Route path={`${match.url}companies`} component={CompanyRoutes} />
        <Route component={Error404} />
      </Switch>
    </Suspense>
  );
};
export default MainAppRoutes;
