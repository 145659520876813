import React, {useState} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import IntlMessages from 'utils/IntlMessages';

const Popup = ({
  onClose,
  title,
  message,
  visible = false,
  children,
  textColor
}) => {
  const [ isOpen, setOpen ] = useState(false);

  const handleRequestClose = () => {
    setOpen(false);
    onClose();
  };

  const open = () => {
    setOpen(true);
  };

  return (
    <React.Fragment>
      <Dialog
        open={isOpen || visible}
        onClose={handleRequestClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={textColor}>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleRequestClose}
            color="secondary"
          >
            {<IntlMessages id="close" />}
          </Button>
        </DialogActions>
      </Dialog>
      {children &&
        React.cloneElement(children, {
          onClick: () => open()
        })}
    </React.Fragment>
  );
};

export default Popup;
