import {baseUrl, get} from './defaults';

export const getCountries = () => {
  return get(`${baseUrl}/lookup`);
};

export const getLookups = params => {
  const queryString = new URLSearchParams(params).toString();
  return get(`${baseUrl}/lookup?${queryString}`);
};

export const getServicesStatuses = () => {
  return get(`${baseUrl}/services/statuses`);
};

export const getCurrencies = () => {
  return get(`${baseUrl}/currencies`);
};

export const getAppCurrency = () => {
  return get(`${baseUrl}/getAppCurrency`);
};

export const getTimezones = (timeZoneId = '') => {
  return get(`${baseUrl}/time/zones/${timeZoneId}`);
};

export const getTimezone = timeZoneId => {
  return get(`${baseUrl}/time/zones/${timeZoneId}`);
};
