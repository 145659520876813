import React from 'react';
import Slide from '@material-ui/core/Slide';

export const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="up" ref={ref} {...props} />;
});

export const tableContainerStyles = (theme, lighten) => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32)
    }
  },
  paper: {
    padding: '20px',
    color: theme.palette.text.secondary,
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    width: '100%'
  },
  container: {
    maxHeight: window.innerHeight - 250,
    border: '1px solid #ffe5e5',
    width: '100%'
  }
});

export const pageContainerStyles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: 1
  },
  contentArea: {
    width: '100%'
  },
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32)
    }
  }
});

export const modalContainerStyles = () => ({
  box: {
    padding: '10px'
  }
});
