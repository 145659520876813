import {baseUrl, get, post} from './defaults';

export const getCountries = () => {
  return get(`${baseUrl}/countries`);
};

export const getCities = countryId => {
  return get(`${baseUrl}/countries/${countryId}/cities`);
};

export const createCity = (countryId, name) => {
  return post(`${baseUrl}/countries/${countryId}/cities`, {name});
};
