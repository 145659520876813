import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Button, Dialog} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {makeStyles} from '@material-ui/styles';
import IntlMessages from 'utils/IntlMessages';
//common modal container styles
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20,
    marginBottom: 20
  }
}));
const RefreshApp = () => {
  const classes = useStyles();
  const {refreshApp} = useSelector(({refreshApp}) => refreshApp);
  useEffect(() => {}, [ refreshApp ]);

  return (
    <React.Fragment>
      {refreshApp && (
        <Dialog
          open={true}
          fullWidth={true}
          maxWidth="xs"
          PaperProps={{
            style: {
              backgroundColor: '#FFF3E5',
              boxShadow: 'none'
            }
          }}
        >
          <Alert
            className={classes.root}
            severity="warning"
            action={
              <Button
                variant="container"
                color="inherit"
                size="small"
                onClick={() => window.location.reload(true)}
              >
                <IntlMessages id={'Reload'} />
              </Button>
            }
          >
            App updated, please reload page.
          </Alert>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default RefreshApp;
