import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';

import Auth from './Auth';
import Common from './Common';
import ReactNotification from './ReactNotification';
import PopupAlert from './PopupAlert';
import SelectedContext from './SelectedContext';
import Country from './Country';
import Lookup from './Lookup';
import RefreshApp from './RefreshApp';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    common: Common,
    reactNotification: ReactNotification,
    popupAlert: PopupAlert,
    selectedContext: SelectedContext,
    country: Country,
    lookup: Lookup,
    refreshApp: RefreshApp
  });
