import {FETCH_ALL_COUNTRIES_SUCCESS} from 'constants/ActionTypes';

const INIT_STATE = {
  countries: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload.rows
      };
    default:
      return state;
  }
};
