import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../utils/IntlMessages';
import {useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {Box, alpha} from '@material-ui/core';
import ContentLoader from '../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import {LockOutlined} from '@material-ui/icons';
import {useHistory, useParams} from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import {showNotificationMessage} from '../../redux/actions/ReactNotification';
import {
  authenticatePasswordToken,
  resetPassword
} from '../../services/authservices';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '45%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      order: 1,
      display: 'none'
    }
  },
  authContent: {
    padding: 30,
    width: '55%',
    alignSelf: 'center',
    height: '100vh',
    [theme.breakpoints.down('sm')]: {
      width: props => (props.variant === 'default' ? '100%' : '100%'),
      order: 2
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50
    }
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12)
    }
  }
}));

const ResetPassword = ({variant = 'default', wrapperVariant = 'default'}) => {
  const [ newPassword, setNewPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState('');
  const [ newPassVal, setNewPassVal ] = useState(false);
  const [ confirmPassVal, setConfirmPassVal ] = useState(false);
  const [ capitalCase, setCapitalCase ] = useState(false);
  const [ smallCase, setSmallCase ] = useState(false);
  const [ digits, setDigits ] = useState(false);
  const [ specialCharacter, setSpecialCharactes ] = useState(false);
  const [ minLength, setMinimumLength ] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({variant});
  const history = useHistory();
  const params = useParams();

  useEffect(
    () => {
      (async function authToken(){
        const response = await authenticatePasswordToken(
          params.passwordResetToken
        );
        if (response.status && response.status >= 400) {
          dispatch(showNotificationMessage('error', response.message));
          history.push('/');
        }
      })();
    },
    [ params, dispatch, history ]
  );
  const handleNewPasswordChange = value => {
    setNewPassword(value);
    const capcaseschema = /.*[A-Z].*/;
    const smallcaseschema = /.*[a-z].*/;
    const specialcaseschema = new RegExp('(?=.*[!@#$%^&*])');
    const digitsSchema = /.*[0-9].*/;

    if (digitsSchema.test(value)) {
      setDigits(true);
    } else {
      setDigits(false);
    }

    if (capcaseschema.test(value)) {
      setCapitalCase(true);
    } else {
      setCapitalCase(false);
    }

    if (smallcaseschema.test(value)) {
      setSmallCase(true);
    } else {
      setSmallCase(false);
    }

    if (specialcaseschema.test(value)) {
      setSpecialCharactes(true);
    } else {
      setSpecialCharactes(false);
    }

    if (value && value.length > 7) {
      setMinimumLength(true);
    } else {
      setMinimumLength(false);
    }
  };

  const onSubmit = async () => {
    setNewPassVal((!newPassword && true) || false);
    setConfirmPassVal((!confirmPassword && true) || false);
    const schema = new RegExp(
      '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
    );
    if (!newPassword && !confirmPassword) {
      dispatch(showNotificationMessage('error', 'Please enter password'));
      return;
    }
    if (newPassword && schema.test(newPassword)) {
      if (newPassword && confirmPassword && newPassword === confirmPassword) {
        const response = await resetPassword(params.passwordResetToken, {
          newPassword,
          confirmPassword
        });
        if (response.status && response.status >= 400) {
          dispatch(showNotificationMessage('error', response.message));
          return;
        }
        dispatch(
          showNotificationMessage('success', 'Password reset successfully.')
        );
        history.push('/');
      } else {
        dispatch(showNotificationMessage('error', 'Password did not matched'));
      }
    } else {
      dispatch(showNotificationMessage('error', 'Password validation error'));
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={`bgGrey col1 ${classes.authThumb}`}>
          <CmtImage className="logo" src={'/images/logo.svg'} />
        </Box>
      ) : null}

      <Box className={classes.authContent}>
        <div className="formWrapper">
          <Box mb={6} className="text-center">
            <CmtImage src="images/logo.svg" width="200" textalign="center" />
          </Box>
          <Typography
            component="div"
            variant="h1"
            className={`${classes.titleRoot} text-center`}
          >
            <IntlMessages id="appModule.CDOSAbbr" />
          </Typography>
          <Typography
            component="div"
            variant="h6"
            className={`${classes.titleRoot} mt-2`}
          >
            <IntlMessages id="appModule.resetPasswordText" />
          </Typography>
          <Typography
            component="div"
            variant="h3"
            className={`${classes.titleRoot} mt-2`}
          >
            <IntlMessages id="appModule.resetPassword" />
          </Typography>

          <form>
            <Box mb={2}>
              <TextField
                type="password"
                label={<IntlMessages id="appModule.newPassword" />}
                fullWidth
                onChange={event => handleNewPasswordChange(event.target.value)}
                defaultValue={newPassword}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                error={newPassVal}
                helperText={newPassVal && 'Enter new password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined color="primary" />
                    </InputAdornment>
                  )
                }}
              />
            </Box>
            <Box mb={2}>
              <TextField
                type="password"
                label={<IntlMessages id="appModule.confirmPassword" />}
                fullWidth
                onChange={event => setConfirmPassword(event.target.value)}
                defaultValue={confirmPassword}
                margin="normal"
                variant="outlined"
                className={classes.textFieldRoot}
                error={confirmPassVal}
                helperText={confirmPassVal && 'Enter confirm password'}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlined color="primary" />
                    </InputAdornment>
                  )
                }}
              />
            </Box>

            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <ul className="pl-4">
                <li
                  style={{color: minLength ? 'green' : 'red', fontSize: '10px'}}
                >
                  {' '}
                  Min 8 characters
                </li>
                <li
                  style={{
                    color: capitalCase ? 'green' : 'red',
                    fontSize: '10px'
                  }}
                >
                  {' '}
                  Atleast 1 cap case
                </li>
                <li
                  style={{color: smallCase ? 'green' : 'red', fontSize: '10px'}}
                >
                  {' '}
                  Atleast 1 small case
                </li>
                <li style={{color: digits ? 'green' : 'red', fontSize: '10px'}}>
                  {' '}
                  Atleast 1 digit
                </li>
                <li
                  style={{
                    color: specialCharacter ? 'green' : 'red',
                    fontSize: '10px'
                  }}
                >
                  {' '}
                  Atleast 1 special character
                </li>
              </ul>
              <Button onClick={onSubmit} variant="contained" color="primary">
                <IntlMessages id="appModule.submit" />
              </Button>
            </Box>
          </form>
          <ContentLoader />
          <div
            className={`row ${(window.innerHeight < 600 && 'mt-3') || 'mt-5'}`}
          >
            <div
              className={`col-12  text-center ${(window.innerHeight < 600 &&
                'mt-0') ||
                'mt-5'}`}
            >
              &copy; <IntlMessages id={'allRightsReserves'} />
            </div>
          </div>
        </div>
      </Box>
    </AuthWrapper>
  );
};

export default ResetPassword;
