export const getCompanyId = (authUser = {}, selectedContext = {}) => {
  return authUser.isSuperAdmin
    ? selectedContext.selectedCompany &&
      selectedContext.selectedCompany &&
      selectedContext.selectedCompany.id
    : authUser.isCompanyAdmin
      ? authUser.adminInCompanyId
      : authUser.isShippingLineAdmin
        ? authUser.shippingLineCompanyId
        : authUser.isTerminalAdmin
          ? authUser.terminalCompanyId
          : authUser.terminals[Object.keys(authUser.terminals)[0]].roles[0]
              .Terminal.depotCompanyId;
};

export const getTerminalId = (authUser = {}, selectedContext = {}) => {
  return authUser.isSuperAdmin ||
  authUser.isCompanyAdmin ||
  authUser.isShippingLineAdmin
    ? selectedContext.selectedTerminal &&
      selectedContext.selectedTerminal &&
      selectedContext.selectedTerminal.id
    : authUser.isTerminalAdmin
      ? authUser.adminInTerminalId
      : authUser.terminals[Object.keys(authUser.terminals)[0]].roles[0]
          .terminalId;
};
