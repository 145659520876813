import React, {useContext} from 'react';
import {Box} from '@material-ui/core';
import CmtList from '@coremat/CmtList';
import CmtImage from '@coremat/CmtImage';
import {flags, languageData} from 'constants/LanguageData';
import LanguageItem from './LanguageItem';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import IntlMessages from 'utils/IntlMessages';
import {makeStyles} from '@material-ui/core/styles';
import {updateUser} from 'services/user';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20
  },
  sectionHeading: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 20,
    textTransform: 'uppercase'
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200
  },
  selectEmpty: {
    marginTop: theme.spacing(4)
  }
}));

const Messages = props => {
  const classes = useStyles();
  const {locale, updateLocale} = useContext(AppContext);

  const switchLanguage = item => {
    updateUser({
      languageId: item.languageId
    });
    updateLocale(item);
    props.onDrawerClose();
  };

  return (
    <Box>
      <Box className={classes.header}>
        <Box fontSize={22} fontWeight={700}>
          <IntlMessages id={'sidebar.selectLanguage'} />
        </Box>
        <CmtImage src={flags[locale.locale]} />
      </Box>

      <CmtList
        data={languageData}
        renderRow={(item, index) => (
          <LanguageItem key={index} language={item} onClick={switchLanguage} />
        )}
      />
    </Box>
  );
};

export default Messages;
