import {
  SET_SELECTED_COMPANY,
  SET_SELECTED_TERMINAL,
  SET_SELECTED_SHIPPING_LINE,
  RESET_TERMINAL
} from '../../constants/ActionTypes';

export const setSelectedCompany = company => {
  return {
    type: SET_SELECTED_COMPANY,
    payload: company
  };
};

export const setSelectedTerminal = terminal => {
  return {
    type: SET_SELECTED_TERMINAL,
    payload: terminal
  };
};

export const setSelectedShippingLine = shippingLine => {
  return {
    type: SET_SELECTED_SHIPPING_LINE,
    payload: shippingLine
  };
};

export const resetTerminal = () => {
  return {
    type: RESET_TERMINAL
  };
};
