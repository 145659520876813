import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {find as _find} from 'lodash';
import {Box, Divider, makeStyles} from '@material-ui/core';
import AppSwitch from '../../../../Common/formElements/AppSwitch';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  getNotificationSubscriptions,
  notificationSubscription
} from 'services/user';
import IntlMessages from 'utils/IntlMessages';
import {showNotificationMessage} from 'redux/actions/ReactNotification';

const useStyles = makeStyles(theme => ({
  sectionRoot: {
    '&:not(:last-child)': {
      marginBottom: 28
    }
  },
  sectionHeading: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 20,
    textTransform: 'uppercase'
  },
  labelProps: {
    color: theme.palette.text.primary,
    cursor: 'pointer'
  }
}));

const Settings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const authUser = useSelector(({auth}) => auth.authUser);
  const [ croNotification, setCRONotification ] = useState(true);
  const [ eirInNotification, setEIRInNotification ] = useState(true);
  const [ eirOutNotifications, setEIROutNotification ] = useState(true);
  const [ pickupSlipNotifications, setPickupSlipNotifications ] = useState(
    true
  );
  const [ croId, setCROId ] = useState(null);
  const [ eirInId, setEirInId ] = useState(null);
  const [ eirOutId, setEirOutId ] = useState(null);
  const [ pickupSlipId, setPickupSlipId ] = useState(null);

  useEffect(
    () => {
      (async () => {
        if (
          !authUser.isSuperAdmin &&
          !authUser.isCompanyAdmin &&
          !authUser.isShippingLineAdmin
        ) {
          const notificationSubscriptions = await getNotificationSubscriptions({
            userId: authUser && authUser.user && authUser.user.id
          });
          if (notificationSubscriptions.resStatus >= 400) {
            dispatch(
              showNotificationMessage(
                'error',
                notificationSubscriptions.message
              )
            );
          } else {
            const CROSubs = _find(
              notificationSubscriptions.rows,
              row => row.typeId === 1
            );
            const EIRInCROSubs = _find(
              notificationSubscriptions.rows,
              row => row.typeId === 2
            );
            const EIROutCROSubs = _find(
              notificationSubscriptions.rows,
              row => row.typeId === 3
            );
            const pickupSlipCROSubs = _find(
              notificationSubscriptions.rows,
              row => row.typeId === 4
            );

            setCRONotification((CROSubs && CROSubs.shouldNotify) || false);
            setCROId(CROSubs && CROSubs.id);
            setEIRInNotification(
              (EIRInCROSubs && EIRInCROSubs.shouldNotify) || false
            );
            setEirInId(EIRInCROSubs && EIRInCROSubs.id);
            setEIROutNotification(
              (EIROutCROSubs && EIROutCROSubs.shouldNotify) || false
            );
            setEirOutId(EIROutCROSubs && EIROutCROSubs.id);
            setPickupSlipNotifications(
              (pickupSlipCROSubs && pickupSlipCROSubs.shouldNotify) || false
            );
            setPickupSlipId(pickupSlipCROSubs && pickupSlipCROSubs.id);
          }
        }
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ authUser ]
  );

  const updateNotificationSubscriptions = async typeId => {
    const data = {
      id: typeId,
      typeId,
      shouldNotify: false
    };
    if (typeId === 1) {
      data.shouldNotify = !croNotification;
      data.id = croId;
      setCRONotification(!croNotification);
    } else if (typeId === 2) {
      data.shouldNotify = !eirInNotification;
      data.id = eirInId;
      setEIRInNotification(!eirInNotification);
    } else if (typeId === 3) {
      data.shouldNotify = !eirOutNotifications;
      data.id = eirOutId;
      setEIROutNotification(!eirOutNotifications);
    } else if (typeId === 4) {
      data.shouldNotify = !pickupSlipNotifications;
      data.id = pickupSlipId;
      setPickupSlipNotifications(!pickupSlipNotifications);
    }
    const result = await notificationSubscription(data);
    if (result.resStatus >= 400) {
      dispatch(showNotificationMessage('error', result.message));
    } else {
      dispatch(
        showNotificationMessage(
          'success',
          'You have successfully subscribed Notifications'
        )
      );
    }
  };

  return (
    <Box>
      <Box fontSize={22} fontWeight={700} mb={9}>
        <IntlMessages id="sidebar.settings" />
      </Box>
      {!authUser.isSuperAdmin &&
      !authUser.isCompanyAdmin &&
      !authUser.isShippingLineAdmin && (
        <Box className={classes.sectionRoot}>
          <Box className={classes.sectionHeading}>
            {' '}
            <IntlMessages id="sidebar.notificationSettings" />
          </Box>
          <Box pb={2}>
            <ConfirmationDialog
              title={
                !croNotification ? (
                  <IntlMessages id={'userProfile.enableCRONotifications'} />
                ) : (
                  <IntlMessages id={'userProfile.disableCRONotifications'} />
                )
              }
              onAccept={() => {
                updateNotificationSubscriptions(1);
              }}
              message={
                !croNotification ? (
                  <IntlMessages id={'userProfile.enableCROMessage'} />
                ) : (
                  <IntlMessages id={'userProfile.disableCROMessage'} />
                )
              }
            >
              <AppSwitch
                label={<IntlMessages id={'croNotifications'} />}
                labelProps={{className: classes.labelProps}}
                checked={croNotification}
              />
            </ConfirmationDialog>
          </Box>
          <Divider />
          <Box pt={2} pb={2}>
            <ConfirmationDialog
              title={
                !eirInNotification ? (
                  <IntlMessages id={'userProfile.enableEIRInNotifications'} />
                ) : (
                  <IntlMessages id={'userProfile.disableEIRInNotifications'} />
                )
              }
              onAccept={() => {
                updateNotificationSubscriptions(2);
              }}
              message={
                !eirInNotification ? (
                  <IntlMessages id={'userProfile.enableEIRInMessage'} />
                ) : (
                  <IntlMessages id={'userProfile.disableEIRInMessage'} />
                )
              }
            >
              <AppSwitch
                label={<IntlMessages id={'eirInNotifications'} />}
                labelProps={{className: classes.labelProps}}
                checked={eirInNotification}
              />
            </ConfirmationDialog>
          </Box>
          <Divider />
          <Box pt={2} pb={2}>
            <ConfirmationDialog
              title={
                !eirOutNotifications ? (
                  <IntlMessages id={'userProfile.enableEIROutNotifications'} />
                ) : (
                  <IntlMessages id={'userProfile.disableEIROutNotifications'} />
                )
              }
              onAccept={() => {
                updateNotificationSubscriptions(3);
              }}
              message={
                !eirOutNotifications ? (
                  <IntlMessages id={'userProfile.enableEIROutMessage'} />
                ) : (
                  <IntlMessages id={'userProfile.disableEIROutMessage'} />
                )
              }
            >
              <AppSwitch
                label={<IntlMessages id={'eirOutNotifications'} />}
                labelProps={{className: classes.labelProps}}
                checked={eirOutNotifications}
              />
            </ConfirmationDialog>
          </Box>
          <Divider />
          <Box pt={2} pb={2}>
            <ConfirmationDialog
              title={
                !pickupSlipNotifications ? (
                  <IntlMessages
                    id={'userProfile.enablePickupSlipNotifications'}
                  />
                ) : (
                  <IntlMessages
                    id={'userProfile.disablePickupSlipNotifications'}
                  />
                )
              }
              onAccept={() => {
                updateNotificationSubscriptions(4);
              }}
              message={
                !pickupSlipNotifications ? (
                  <IntlMessages id={'userProfile.enablePickupSlipMessage'} />
                ) : (
                  <IntlMessages id={'userProfile.disablePickupSliptMessage'} />
                )
              }
            >
              <AppSwitch
                label={<IntlMessages id={'pickupSlipNotifications'} />}
                labelProps={{className: classes.labelProps}}
                checked={pickupSlipNotifications}
              />
            </ConfirmationDialog>
          </Box>
          <Divider />
        </Box>
      )}
    </Box>
  );
};

export default Settings;
