import {SHOW_POPUP_ALERT, HIDE_POPUP_ALERT} from 'constants/ActionTypes';

export const showPopupAlert = (title, message, textColor) => {
  return {
    type: SHOW_POPUP_ALERT,
    payload: {
      showPopup: true,
      title: title,
      message: message,
      textColor: textColor
    }
  };
};

export const hidePopupAlert = () => {
  return {
    type: HIDE_POPUP_ALERT
  };
};
