import React from 'react';
import {Box, Typography} from '@material-ui/core';
// import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useDispatch} from 'react-redux';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import PersonIcon from '@material-ui/icons/Person';
import {useHistory} from 'react-router';
import {userSignOut} from '../../../../redux/actions/Auth';
import {useSelector} from 'react-redux';

const actionsList = [
  // {
  //   icon: <PersonIcon />,
  //   label: 'Account'
  // },
  {
    icon: <ExitToAppIcon />,
    label: 'Logout'
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: `solid 1px ${theme.palette.sidebar.borderColor}`
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease'
    }
  },
  userTitle: {
    color: theme.palette.sidebar.textDarkColor,
    marginBottom: 8
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25
  }
}));

const SidebarHeader = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const {authUser} = useSelector(({auth}) => auth);

  const onItemClick = item => {
    if (item.label === 'Logout') {
      localStorage.removeItem('x-ctos-token');
      dispatch(userSignOut(history));
    }
  };

  return (
    <React.Fragment>
      <Box className={classes.root}>
        {/* <CmtAvatar src={'https://via.placeholder.com/150x150'} /> */}
        <Box className={classes.userInfo}>
          <CmtDropdownMenu
            onItemClick={onItemClick}
            TriggerComponent={
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="flex-end"
              >
                <Box mr={2}>
                  <Typography
                    className={classes.userTitle}
                    component="h3"
                    variant="h6"
                  >
                    <div className="row ml-1">
                      <p>
                        {authUser &&
                          authUser.user &&
                          authUser.user.firstName.toUpperCase()}
                      </p>
                      <p className="ml-1">
                        {authUser &&
                          authUser.user &&
                          authUser.user.firstName.toUpperCase()}
                      </p>
                    </div>
                  </Typography>
                  <Typography className={classes.userSubTitle}>
                    {authUser && authUser.user && authUser.user.email}
                  </Typography>
                </Box>
                <ArrowDropDownIcon />
              </Box>
            }
            items={actionsList}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default SidebarHeader;
