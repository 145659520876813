import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {updateLocation} from '../redux/actions/User';
import {useHistory} from 'react-router-dom';
import {userSignOut} from '../redux/actions/Auth';
import {makeStyles} from '@material-ui/styles';
import {RecheckLocationInterval} from '../@jumbo/constants/AppConstants';
import {PolyUtil} from 'node-geometry-library';
import {isEmpty as _isEmpty, keys as _keys} from 'lodash';
import PageLoader from 'components/PageComponents/PageLoader';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box
} from '@material-ui/core';
import {modalContainerStyles} from 'constants/Styles';

//common modal container styles
const useStyles = makeStyles(theme => modalContainerStyles(theme));

const FenceWarning = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ isLoading, setIsLoading ] = useState(false);

  const history = useHistory();
  const authUser = useSelector(({auth}) => auth.authUser);
  const userLocationVerified = useSelector(
    ({auth}) => auth.userLocationVerified
  );

  useEffect(
    () => {
      if (
        authUser.isSuperAdmin ||
        authUser.isCompanyAdmin ||
        authUser.isShippingLineAdmin ||
        authUser.isTerminalAdmin
      ) {
        return;
      } else {
        isInsideFence();
        const interval = setInterval(async () => {
          isInsideFence();
        }, RecheckLocationInterval);
        return () => clearInterval(interval);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ authUser, history ]
  );

  const isInsideFence = () => {
    let latitude = null;
    let longitude = null;
    navigator.geolocation.getCurrentPosition(
      async function(position){
        latitude = position.coords.latitude;
        longitude = position.coords.longitude;

        let isLocationVerified = true;
        if (!authUser.user.isLocationEnable) {
          return;
        } else if (
          !_isEmpty(authUser.terminals) &&
          authUser.user.isLocationEnable &&
          latitude &&
          longitude
        ) {
          const terminalId = _keys(authUser.terminals)[0];
          const terminalCoordinates =
            authUser.terminals[terminalId].coordinates;

          if (terminalCoordinates.length > 0) {
            const responseArr = terminalCoordinates.map(fence => {
              return PolyUtil.containsLocation(
                {
                  lat: latitude,
                  lng: longitude
                },
                fence
              );
            });

            if (responseArr.includes(true)) {
              isLocationVerified = true;
            } else {
              isLocationVerified = false;
            }
          } else {
            isLocationVerified = true;
          }
        } else {
          isLocationVerified = true;
        }

        if (isLocationVerified) {
          dispatch(updateLocation(true));
        } else {
          dispatch(updateLocation(false));
        }
      },
      async function(){
        let isLocationVerified = true;

        if (!authUser.user.isLocationEnable) {
          return;
        } else if (!_isEmpty(authUser.terminals)) {
          const terminalKeys = _keys(authUser.terminals);
          const terminalCoordinates = terminalKeys.map(id => {
            return authUser.terminals[id].coordinates;
          });

          if (terminalCoordinates.length > 0) {
            isLocationVerified = false;
          } else {
            isLocationVerified = true;
          }
        } else {
          isLocationVerified = true;
        }

        if (isLocationVerified) {
          dispatch(updateLocation(true));
        } else {
          dispatch(updateLocation(false));
        }
      }
    );
    setIsLoading(false);
  };

  const refresh = () => {
    setIsLoading(true);
    setTimeout(() => {
      isInsideFence();
    }, 500);
  };

  const logout = () => {
    dispatch(userSignOut(history));
  };

  return !userLocationVerified ? (
    <React.Fragment>
      <Dialog
        open={true}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        keepMounted={false}
        fullWidth={true}
        maxWidth="xs"
      >
        {isLoading && <PageLoader />}
        <Box className={classes.box}>
          <DialogTitle id="cargo-modal-dialog-title">
            Location Verification Failed
          </DialogTitle>
          <DialogContent className={'text-red'}>
            You are not authorized to use app outside Depot
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="secondary"
              fullWidth={false}
              onClick={refresh}
            >
              <i className="zmdi zmdi-refresh zmdi-hc-fw" />
              Refresh
            </Button>
            <Button
              className="bg-orange"
              variant="contained"
              fullWidth={false}
              onClick={logout}
            >
              <i className="zmdi zmdi-sign-in zmdi-hc-fw" />
              Logout
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  ) : null;
};
export default FenceWarning;
