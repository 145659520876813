import {applyMiddleware, createStore, compose} from 'redux';
import reducers from '../reducers';
import {createHashHistory} from 'history';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import {routerMiddleware} from 'connected-react-router';
import thunk from 'redux-thunk';

const history = createHashHistory();
const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [ sagaMiddleware, routeMiddleware, thunk ];

function configureStore(initialState = {}){
  const store = createStore(
    reducers(history),
    initialState,
    compose(applyMiddleware(...middlewares))
  );
  sagaMiddleware.run(rootSaga);
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer);
    });
  }
  return store;
}
export default configureStore;
export {history};

// const bindMiddleware = middleware => {
//   if (process.env.NODE_ENV !== 'production') {
//     const { composeWithDevTools } = require('redux-devtools-extension');
//     return composeWithDevTools(applyMiddleware(...middleware));
//   }
//   return applyMiddleware(...middleware);
// };
