import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box
} from '@material-ui/core';
import {Save, Cancel} from '@material-ui/icons';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {modalContainerStyles, Transition} from 'constants/Styles';
import * as constants from 'constants/AppConstants';
import {getCroStatus} from 'services/terminals';
import IntlMessages from 'utils/IntlMessages';

//common modal container styles
const useStyles = makeStyles(theme => modalContainerStyles(theme));

const ISOModal = ({onClose}) => {
  const cargoModalFormRef = useRef('cargoModalFormRef');
  const classes = useStyles();
  const [ open, setOpen ] = useState(true);
  const [ croNumber, setCroNumber ] = useState(null);
  const [ croStatus, setCROStatus ] = useState(null);

  const handleClose = () => {
    setOpen(false);
    onClose(false);
  };

  const handleSubmit = async () => {
    const result = await getCroStatus({cro_number: croNumber});
    setCROStatus(result);
  };

  const handleChange = event => {
    setCroNumber(event.target.value);
  };

  let color = null;
  if (
    (croStatus &&
      croStatus.message &&
      croStatus.message === constants.CRO_BLOCKED) ||
    (croStatus &&
      croStatus.message &&
      croStatus.message === constants.CRO_EXPIRED)
  ) {
    color = 'red';
  } else if (
    croStatus &&
    croStatus.message &&
    croStatus.message === constants.CRO_NOT_FOUND
  ) {
    color = 'blue';
  } else {
    color = 'green';
  }
  return (
    <React.Fragment>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          keepMounted={false}
          fullWidth={true}
          maxWidth="xs"
          onClose={handleClose}
        >
          <ValidatorForm ref={cargoModalFormRef} onSubmit={handleSubmit}>
            <Box className={classes.box}>
              <DialogTitle id="cargo-modal-dialog-title">
                <IntlMessages id="cro.croValidity" />
              </DialogTitle>
              <DialogContent>
                <div className="mb-3">
                  <TextValidator
                    label="Enter CRO Number*"
                    onChange={handleChange}
                    name="cro_number"
                    value={croNumber || ''}
                    validators={[ 'required' ]}
                    errorMessages={[ 'CRO is required' ]}
                    variant="outlined"
                    size="small"
                    className="w-100"
                  />
                </div>
                <div className="mb-2">
                  <div
                    style={{color: color, fontWeight: 'bold'}}
                    className="row mt-3 ml-1"
                  >
                    {croStatus &&
                    croStatus.message &&
                    croStatus.message === true ? (
                      'CRO IS AVAILABLE'
                    ) : (
                      croStatus && croStatus.message && croStatus.message
                    )}
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={handleClose}
                  color="secondary"
                  fullWidth={false}
                  startIcon={<Cancel />}
                >
                  <IntlMessages id={'cancel'} />
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth={false}
                  startIcon={<Save />}
                >
                  <IntlMessages id={'check'} />
                </Button>
              </DialogActions>
            </Box>
          </ValidatorForm>
        </Dialog>
      )}
    </React.Fragment>
  );
};
export default ISOModal;
