import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {hidePopupAlert} from '../redux/actions/PopupAlert';
import Popup from 'components/Popup';

const PopupAlert = () => {
  const {showPopup, title, message, textColor} = useSelector(
    ({popupAlert}) => popupAlert
  );
  const dispatch = useDispatch();

  useEffect(() => {}, [ showPopup, title, message ]);

  const handlePopupClose = () => {
    dispatch(hidePopupAlert());
  };

  return (
    <div>
      {showPopup ? (
        <Popup
          visible={showPopup}
          title={title}
          message={message}
          textColor={textColor}
          onClose={handlePopupClose}
        />
      ) : null}
    </div>
  );
};
export default PopupAlert;
