import React from 'react';
import {Box, Button, makeStyles} from '@material-ui/core';
import SearchBox from './SearchBox';
import CmtList from '../../../../../../../@coremat/CmtList';
import EmptyResult from '../EmptyResult';
import CmtMediaObject from '../../../../../../../@coremat/CmtMediaObject';
import CmtAvatar from '../../../../../../../@coremat/CmtAvatar';
import IntlMessages from 'utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  requestDetailRoot: {
    padding: '12px 0',
    borderBottom: `solid 1px ${theme.palette.divider}`,
    '& .Cmt-media-object': {
      alignItems: 'center'
    }
  },
  sectionHeading: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 20,
    textTransform: 'uppercase'
  },
  avatarSize: {
    height: 40,
    width: 40,
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      height: 56,
      width: 56
    }
  },
  titleRoot: {
    fontSize: 14,
    cursor: 'pointer'
  },
  subTitleRoot: {
    fontSize: 12,
    letterSpacing: 0.4,
    color: theme.palette.text.disabled
  }
}));

const Search = () => {
  const classes = useStyles();
  const totalRequests = 0;

  return (
    <Box className={classes.root}>
      <Box fontSize={22} fontWeight={700} mb={7}>
        <IntlMessages id="sidebar.search" />
      </Box>
      <SearchBox searchKeyword={''} />
      {totalRequests ? (
        <CmtList
          data={[]}
          renderRow={(item, index) => <RenderRow key={index} item={item} />}
        />
      ) : (
        <EmptyResult content="No record found" />
      )}
    </Box>
  );
};

const RenderRow = ({item}) => {
  const classes = useStyles();

  return (
    <Box className={classes.requestDetailRoot}>
      <CmtMediaObject
        avatarPos="center"
        avatar={
          <CmtAvatar
            className={classes.avatarSize}
            src={item.user.profile_pic}
            alt={item.user.name}
          />
        }
        title={item.user.name}
        titleProps={{
          variant: 'h4',
          className: classes.titleRoot
        }}
        subTitle={`@${item.user.username}`}
        subTitleProps={{
          variant: 'body2',
          className: classes.subTitleRoot
        }}
        actionsComponent={
          <Box display="flex">
            <Button
              size="small"
              color="primary"
              variant="contained"
              style={{marginRight: 10}}
            >
              <IntlMessages id="sidebar.accept" />
            </Button>
            <Button size="small" color="primary">
              <IntlMessages id="sidebar.cancel" />
            </Button>
          </Box>
        }
      />
    </Box>
  );
};

export default Search;
