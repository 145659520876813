import React from 'react';
import {ConnectedRouter} from 'connected-react-router';
import {Provider} from 'react-redux';
import {Switch, Route} from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import configureStore, {history} from './redux/store';
import AppWrapper from './@jumbo/components/AppWrapper';
import Notifications from 'components/ReactNotification';
import PopupAlert from 'components/PopupAlert';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import App from './containers/App';
import RefreshApp from 'components/RefreshApp';

export const store = configureStore();

const MainApp = () => (
  <AppContextProvider>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppWrapper>
          <RefreshApp />
          <Switch>
            <Route path="/" component={App} />
          </Switch>
          <Notifications />
          <PopupAlert />
        </AppWrapper>
      </ConnectedRouter>
    </Provider>
  </AppContextProvider>
);

export default MainApp;
