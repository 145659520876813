import {baseUrl, get, post, put, toggle} from './defaults';

export const getCompanyShippingLines = (companyId, options) => {
  if (!options) {
    options = {
      limit: 'all'
    };
  }
  const pageOptions = new URLSearchParams(options).toString();
  return get(`${baseUrl}/companies/${companyId}/shipping-lines?${pageOptions}`);
};

export const getShippingLine = (companyId, shippingLineId) => {
  let firmType = {type: 'Shipping Line'};
  const entityType = new URLSearchParams(firmType).toString();
  return get(
    `${baseUrl}/companies/${companyId}/shipping-lines/${shippingLineId}?${entityType}`
  );
};

export const toggleShippingLineStatus = (companyId, shippingLineId) => {
  let firmType = {type: 'Shipping Line'};
  const entityType = new URLSearchParams(firmType).toString();
  return toggle(
    `${baseUrl}/companies/${companyId}/shipping-lines/${shippingLineId}/toggle-status?${entityType}`
  );
};

export const editShippingLine = (companyId, shippingLine, shippingLineId) => {
  let firmType = {type: 'Shipping Line'};
  const entityType = new URLSearchParams(firmType).toString();
  return put(
    `${baseUrl}/companies/${companyId}/shipping-lines/${shippingLineId}?${entityType}`,
    shippingLine
  );
};

export const saveShippingLine = (companyId, shippingLine) => {
  let firmType = {type: 'Shipping Line'};
  const entityType = new URLSearchParams(firmType).toString();
  return post(
    `${baseUrl}/companies/${companyId}/shipping-lines?${entityType}`,
    shippingLine
  );
};

export const orderShippingLines = (companyId, shippingLines) => {
  return put(
    `${baseUrl}/companies/${companyId}/shipping-lines/order`,
    shippingLines
  );
};

// ============================ Container Grading Services ===========================================================================

export const getContainerGradings = (companyId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/companies/${companyId}/container_grades?${qs}`);
};

export const getContainerGradesByAttributes = (companyId, params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(
    `${baseUrl}/companies/${companyId}/container_grades/by-attributes?${qs}`
  );
};

export const getContainerGrading = (companyId, id) => {
  return get(`${baseUrl}/companies/${companyId}/container_grades/${id}`);
};

export const saveContainerGrade = (container_gradings, companyId, id = '') => {
  const type = (id && put) || post;
  return type(
    `${baseUrl}/companies/${companyId}/container_grades/${id}`,
    container_gradings
  );
};

export const addContainerGrade = (companyId, container_gradings) => {
  return post(
    `${baseUrl}/companies/${companyId}/container_grades/`,
    container_gradings
  );
};

export const editContainerGrade = (companyId, id, container_gradings) => {
  return put(
    `${baseUrl}/companies/${companyId}/container_grades/${id}`,
    container_gradings
  );
};

export const orderGrades = (companyId, containerGrades) => {
  return put(
    `${baseUrl}/companies/${companyId}/container_grades/order_grades`,
    containerGrades
  );
};

export const toggleContainerGrade = (companyId, id) => {
  return toggle(
    `${baseUrl}/companies/${companyId}/container_grades/toggle-status/${id}`
  );
};

export const saveContainerGrades = (companyId, data) => {
  return post(`${baseUrl}/companies/${companyId}/container_grades/bulk`, data);
};
