import {baseUrl, get, put} from './defaults';

export const updateUser = user => {
  return put(`${baseUrl}/user/profile`, user);
};

export const updatePassword = userPassword => {
  return put(`${baseUrl}/user/password/change`, userPassword);
};

export const getCurrentUser = () => {
  return get(`${baseUrl}/user/profile`);
};

export const getNotificationTypes = () => {
  return get(`${baseUrl}/notification/types`);
};

export const notificationSubscription = subscriptions => {
  return put(`${baseUrl}/notification/subscriptions`, subscriptions);
};

export const getNotificationSubscriptions = (params = '') => {
  let qs = new URLSearchParams(params).toString();
  return get(`${baseUrl}/notification/subscriptions?${qs}`);
};
