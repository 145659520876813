import {baseUrl, headers, get} from './defaults';

export const signIn = (
  username,
  password,
  latitude,
  longitude,
  recaptchaToken
) => {
  return fetch('/login', {
    headers: headers(),
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      username: username,
      password: password,
      latitude: latitude,
      longitude: longitude,
      recaptchaToken: recaptchaToken
    })
  }).then(res => res.json());
};

export const signOutMe = () => {
  return fetch('/logout', {
    headers: headers(),
    method: 'get'
  });
};

export const userContext = () => {
  localStorage.removeItem('app_version');
  return get(`${baseUrl}/user-context`);
};

export const updateExpiredPassword = data => {
  return fetch('/api/user/password/update', {
    headers: headers(),
    credentials: 'include',
    method: 'PUT',
    body: JSON.stringify(data)
  }).then(res => res.json());
};

export async function forgotPassword(username){
  return fetch('/api/user/forgot-password', {
    headers: headers(),
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify(username)
  });
}

export async function resetPassword(token, data){
  return fetch(`/api/user/reset-password/${token}`, {
    headers: headers(),
    credentials: 'include',
    method: 'PUT',
    body: JSON.stringify(data)
  });
}

export async function authenticatePasswordToken(token){
  return fetch(`/api/user/authenticate-password-token/${token}`, {
    headers: headers(),
    credentials: 'include',
    method: 'GET'
  }).then(res => res.json());
}

export const verifyLocation = (latitude, longitude) => {
  return fetch('/api/user/verifyLocation', {
    headers: headers(),
    credentials: 'include',
    method: 'POST',
    body: JSON.stringify({
      latitude: latitude,
      longitude: longitude
    })
  }).then(res => res.json());
};

export async function checkIfIpIsBlocked(){
  return fetch('/is-ip-blocked', {
    headers: headers(),
    credentials: 'include',
    method: 'GET'
  }).then(res => res.json());
}
