import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, makeStyles} from '@material-ui/core';
import {useHistory} from 'react-router';
import {each as _each, first as _first} from 'lodash';
import moment from 'moment-timezone';
import CmtList from '../../../../../../@coremat/CmtList';
import NotificationItem from '../../../partials/Header/HeaderNotifications/NotificationItem';
import EmptyResult from './EmptyResult';
import {getNotifications} from 'services/terminals';
import {getCompanyId, getTerminalId} from 'helpers/context';
import {showNotificationMessage} from 'redux/actions/ReactNotification';
import IntlMessages from '@jumbo/utils/IntlMessages';

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20
  },
  sectionHeading: {
    fontSize: 10,
    color: theme.palette.text.secondary,
    marginBottom: 20,
    textTransform: 'uppercase'
  }
}));

const Notifications = props => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector(({auth}) => auth.authUser);
  const selectedContext = useSelector(({selectedContext}) => selectedContext);
  const companyId = getCompanyId(authUser, selectedContext);
  const terminalId = getTerminalId(authUser, selectedContext);
  const [ notificationsData, setNotificationsData ] = useState([]);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    const notificationsData = [];
    const notifications = await getNotifications(companyId, terminalId, {
      limit: 25
    });
    if (notifications.resStatus >= 400) {
      dispatch(showNotificationMessage('error', notifications.message));
      setNotificationsData(notificationsData);
    } else {
      _each(notifications.rows, n => {
        notificationsData.push({
          id: n.id,
          notificationId: n.Notification.id,
          image: n.Notification.User.picture.path,
          title: n.Notification.detailedJSON.message,
          time: moment
            .tz(
              n.Notification.createdAt,
              _first(selectedContext.selectedTerminal.Timezone.utc)
            )
            .format('LLL'),
          isRead: n.isRead,
          type: 'DEFAULT'
        });
      });
      setNotificationsData(notificationsData);
    }
  };

  const viewAllNotifications = () => {
    history.push(
      `/companies/${companyId}/terminals/${terminalId}/notifications`
    );
    props.onDrawerClose();
  };

  return (
    <Box>
      <Box className={classes.header}>
        <Box fontSize={22} fontWeight={700}>
          <IntlMessages id="appNotification.notifications" />
        </Box>
        <Button color="primary" onClick={() => viewAllNotifications()}>
          <IntlMessages id="appNotification.viewAll" />
        </Button>
      </Box>
      <Box className={classes.sectionHeading}>
        <IntlMessages id="appNotification.latestNotifications" />
      </Box>
      {notificationsData.length > 0 ? (
        <CmtList
          data={notificationsData}
          renderRow={(item, index) => (
            <NotificationItem key={index} item={item} />
          )}
        />
      ) : (
        <EmptyResult content="No record found" />
      )}
    </Box>
  );
};

export default Notifications;
