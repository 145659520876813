import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS
} from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  };
};
export const fetchError = error => {
  return {
    type: FETCH_ERROR,
    payload: error
  };
};

export const fetchStart = () => {
  return {
    type: FETCH_START
  };
};
